import React, { useState } from "react";
import { tracking, createTrackingEvent } from "lib/tracking";
import loadable from "@loadable/component";
import * as widont from "widont";
import { useForm } from "react-hook-form";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import PropTypes from "prop-types";
import * as FormStyling from "./Form.module.scss";
import { graphql } from "gatsby";
import api from "../../../lib/api";
import Recaptcha from "components/Recaptcha/recaptcha.js";
const InputType = loadable(() => import('./FormElements/InputType'));
const Textarea = loadable(() => import('./FormElements/Textarea'));
const TermsConditions = loadable(() => import('./FormElements/TermsConditions'));
const SelectOption = loadable(() => import('./FormElements/SelectOption'));
const InputHidden = loadable(() => import('./FormElements/InputHidden'));

const Form = ({ block }) => {
  const title = widont(block.form.title);
  const subTitle = widont(block.form.subtitle);
  const formData = block.form.items;
  const submitButtonText = block.form.submitButtonText;
  const termsConditions = block.form.showTermsAndConditions;
  const rightSideContent = block.form.rightSideContent;
  const successMessage = block.form.successMessage;
  const sendDataToSalesforce = block.form.sendDataToSalesforce;
  const eventName = `Storefront - Submit Soundtrack ${block.form.name} Form`;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const recaptchaRef = React.createRef();
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const salesforceFieldsMap = {
    campaign: '00N1r00000KMn3X',
    content: '00N1r00000KMn3Y',
    medium: '00N1r00000KMn3Z',
    source: '00N1r00000KMn3a',
    clickIds: '00N6900000LFbVK',
  }

  const onSubmit = (data) => {
    createTrackingEvent(
      eventName,
      {},
      "Submit",
      `Submit Soundtrack ${block.form.name} Form`
    );

    if (sendDataToSalesforce)
      return tracking.then(t => {
        const utmObject = t.utm
        const utmMapped = {}
        if(t.clickIds) utmMapped[salesforceFieldsMap.clickIds] = t.clickIds

        for (const prop in utmObject) {
          if(utmObject[prop]) utmMapped[salesforceFieldsMap[prop]] = utmObject[prop]
        }
        const salesforceData = { ...utmMapped, ...data }
        // Use temporary WebToLead Post action, should be replaced with Salesforce Rest API ASAP.
        api.sendDataToWebToLead(salesforceData).then(() => {
          setLoading(false);
          setFormSent(true);
          reset();
        });
      })
  };

  return (
    <Section row="r-top" className={FormStyling.formWrapper}>
      <div className={`col sm-12 m-5 ${FormStyling.leftSideContainer}`}>
        <div className="mb-7">
          <p className={`text5 mb-0 ${FormStyling.title}`}>{title}</p>
          <p className={`text5 ${FormStyling.subTitle}`}>{subTitle}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {formData.map((form) => (
            <React.Fragment key={form.id}>
              {(() => {
                switch (form.__typename) {
                  case "DatoCmsInputtypehidden":
                    return (
                      <InputHidden
                        type="hidden"
                        name={form.name}
                        value={form.value}
                        register={register}
                      />
                    );
                  case "DatoCmsInputtypeemail":
                  case "DatoCmsInputtypetext":
                    return (
                      <InputType
                        label={form.label}
                        name={form.name}
                        register={register}
                        errors={errors}
                        description={form.description}
                        isRequired={form.isrequired}
                      />
                    );
                  case "DatoCmsInputtypetextarea":
                    return (
                      <Textarea
                        label={form.label}
                        name={form.name}
                        register={register}
                        errors={errors}
                        description={form.description}
                        isRequired={form.isrequired}
                      />
                    );
                  case "DatoCmsInputselectoption":
                    return (
                      <SelectOption
                        label={form.label}
                        name={form.name}
                        premadeOptions={form.premadeOptions}
                        setFormValue={setValue}
                        isRequired={form.isrequired}
                        customOptions={form.customOptions}
                        errors={errors}
                        register={register}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </React.Fragment>
          ))}
          {termsConditions && (
            <TermsConditions register={register} errors={errors} />
          )}
          <div className="field">
            <Recaptcha
              recaptchaRef={recaptchaRef}
              onChange={() => {
                setButtonDisabled(false);
              }}
            />
          </div>
          <Button
            primary
            type="submit"
            loading={loading}
            disabled={buttonDisabled}
          >
            {submitButtonText}
          </Button>
          {formSent && (
            <div className="mono-container">
              <StructuredTextRenderer data={successMessage} />
            </div>
          )}
        </form>
      </div>
      <div className={`col c-1 hide-to-m ${FormStyling.centerContainer}`}></div>
      <div
        className={`col sm-12 m-6 mono-container ${FormStyling.rightSideContainer}`}
      >
        <StructuredTextRenderer data={rightSideContent} />
      </div>
    </Section>
  );
};

export default Form;

Form.propTypes = {
  block: PropTypes.shape({
    form: PropTypes.shape({
      rightSideContent: PropTypes.object,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          name: PropTypes.string,
          description: PropTypes.string,
          isRequired: PropTypes.bool,
        }),
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          completeSelect: PropTypes.string,
          isrequired: PropTypes.string,
          customOptions: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
            })
          ),
        })
      ).isRequired,
    }),
  }),
};

export const query = graphql`
  fragment FormReference on DatoCmsFormreference {
    model {
      apiKey
    }
    id: originalId
    form {
      name
      title
      subtitle
      showTermsAndConditions
      submitButtonText
      sendDataToSalesforce
      action
      successMessage {
        value
      }
      rightSideContent {
        value
        blocks {
          __typename
          ...List
        }
      }
      items {
        __typename
        ...InputTypeHidden
        ...InputTypeEmail
        ...InputTypeTextarea
        ...InputTypeText
        ...InputSelectOption
      }
    }
  }
`;
